.App {
  height: 100vh;
  overflow: hidden;
}

.order-wrapper {
  display: flex;
  height: 80vh;
  width: 100%;
  /* display: grid;
  grid-template-columns: repeat(3, auto);
  padding: 8px 16px; */
  gap: 8px;
  padding: 8px;
  justify-content: center;
  align-items: center;
}

.home-wrapper {
  display: grid;
  /* grid-template-columns: repeat(3, auto); */
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  padding: 8px 16px;
  gap: 8px;
}

@media (min-width: 1024px) {
  .home-wrapper {
    grid-template-columns: repeat(3, minmax(300px, 1fr));
  }
}

@media (min-width: 801px) {
  .table-filter-wrap > div:first-child {
    width: 100%;
    overflow-y: auto;
  }

  .ant-table-content {
    overflow-x: auto;
    overflow-y: hidden;
    /* width: 100%; */
    height: fit-content;
  }
}

form {
  scroll-behavior: smooth;
}

form label {
  font-weight: bold;
}

form label span {
  font-weight: normal;
}

.home-tile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  font-size: 24px;

  height: 191px;
  /* width: 375px; */
  cursor: pointer;

  background: #1890ff;

  color: #fff;
  border-radius: 4px;
}

.ant-collapse {
  margin-top: 8px !important;
}

.ant-collapse-item {
  background-color: #fff;
}

.ant-collapse,
.ant-collapse-content,
.ant-collapse-content-box {
  border: none !important;
}

.ant-collapse-header {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  border-bottom: 1px solid #f0f0f0;
}

.order-tile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  font-size: 24px;

  height: 191px;
  width: 400px;
  cursor: pointer;

  background: #1890ff;

  color: #fff;
  border-radius: 4px;
}

.home-tile:hover {
  background: #55adff;
  color: #fff;
}

.site-page-header {
  background-color: #fff !important;
}

.login-wrapper {
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-window {
  width: 456px;
  height: 460px;
  padding: 25px 50px 25px 50px;

  background: #12191f;
}

.login-logo {
  height: 34.52px;
  width: 285.71px;
  background-repeat: no-repeat;
  background-size: cover;
}

.side-logo {
  height: 50px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-right: 15px;
}

.login-title,
.login-subtitle {
  color: #fff !important;
  text-align: center;
}

.login-subtitle {
  width: 250px;
}

.login-window input,
.login-window .ant-input-affix-wrapper,
.login-window svg {
  background-color: #12191f !important;
  color: #fff !important;
}

.login-window button {
  width: 182px;
  background-color: #fff;
  color: #12191f;
  border-color: #12191f;
}

.login-window button:hover {
  background-color: #ccc;
  color: #12191f;
}

.login-window .submit-button {
  margin: 0 !important;
}

.login-window .ant-form-item-control {
  max-width: 100%;
  width: 100%;
}

.input-wrap {
  width: 100%;
}

.login-window form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}

.grid-images-view {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-auto-rows: minmax(auto, auto);
  grid-gap: 8px;
  /* padding: 8px 16px; */

  /* justify-content: center; */

  align-items: center;
  justify-items: center;
}

.grid-image-item {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.section-from-vertical {
  gap: 8px;
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.section-from-horizontal {
  display: flex;
  width: 100%;
  gap: 8px;
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}

.has-selection .selected {
  background-color: #ecf6ff;
}

.has-selection td > div,
.order-table td > div {
  padding: 0 3px;
  height: 55px;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}

.has-selection td > div,
.order-table span.ant-select-selection-item,
.has-selection td > div,
.order-table span.ant-badge-status-text {
  font-size: 0.75rem;
}

.order-table .ant-select {
  max-width: 170px;
}

.order-table .ant-table-thead .ant-table-cell {
  font-size: 0.75rem;
  padding: 5px;
}

.order-table td > div > button,
.order-table td > div > .ant-select {
  margin-top: -4px;
}

.has-selection td,
.order-table td {
  padding: 0 !important;
}

@media (max-width: 1100px) {
  .has-selection td > div,
  .order-table td > div {
    font-size: 0.6rem;
  }

  .has-selection td > div,
  .order-table span.ant-select-selection-item,
  .has-selection td > div,
  .order-table span.ant-badge-status-text {
    font-size: 0.6rem;
  }

  .order-table .ant-select {
    max-width: 150px;
  }

  .order-table th:nth-child(3) {
    min-width: 70px;
  }

  .order-table .ant-table-thead .ant-table-cell {
    font-size: 0.6rem;
    padding: 3px;
  }
}

.order-table .in_preparation {
  background-color: #fffbdb;
}
.order-table .in_production {
  background-color: #ffe7e2;
}
.order-table .cancelled {
  background-color: #ffffff;
}
.order-table .ready_to_pick_up {
  background-color: #e3ffe2;
}
.order-table .on_the_way_client {
  background-color: #dfd3ee;
}
.order-table .on_the_way_store {
  background-color: #dbf0ff;
}
.order-table .order_finished {
  background-color: #e9e9e9;
}

.order-btn {
  border-radius: 5px;
  border: 1px solid #d3d6e0;
  cursor: pointer;
}

.order-btn.small {
  padding: 10px 12px;
}

.order-btn.big {
  width: 137px;
  height: 61px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.order-btn.auto{
  max-width: 137px;
  width: calc((100% - 12px * 4)/5);
  height: 61px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-btn.selected,
.order-btn.selected span {
  color: #fff !important;
  background-color: #1890ff;
  border-color: #1890ff;
}

.metrics-wrapper {
  padding: 24px;
  width: 100%;
}

.metrics-wrapper .stat-card {
  width: 337px;
}

.metric-filters {
  background-color: #fff;
  width: 100%;
  padding: 0 24px;
  /* width: 190px; */
}

.metric-filters .ant-select,
.metric-filters .ant-picker {
  width: 190px !important;
}

.orders-tabs .ant-tabs-nav {
  padding: 0 24px;
  background-color: #fff;
}
.detail-general-order .ant-card .ant-tabs-nav-wrap {
  padding: 0 !important;
  background-color: transparent !important;
}

.detail-general-order .ant-form-item {
  margin: 0;
}

.detail-general-order .ant-form-item-control {
  height: 32px;
}

/* .ant-radio-inner::after {
  background-color: black !important;
} */

/* .ant-radio-checked .ant-radio-inner {
  border-color: #d9d9d9 !important;
} */

/* .ant-radio-checked .ant-radio-inner:hover {
  border-color: black !important;
} */

/* ::selection {
  background: black !important;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: black !important;
} */

.user-detail-tabs .ant-tabs-nav {
  background-color: #fff;
  padding: 0 15px;
  margin: 0 !important;
}

.shoes-radio .ant-radio-group > label:nth-child(3) {
  display: block;
  margin-bottom: 5px;
}

.shoes-radio .ant-radio-group > label:nth-child(5) {
  width: 50%;
  margin-bottom: 5px;
}

.shoes-radio .ant-radio-group > label:nth-child(8) {
  display: block;
}

.shoes-radio .ant-radio-group > label:nth-child(9) {
  display: block;
}

/* #orderProducts\.0\.orderCustom\.orderCustomShoes\.design > div > div.ant-col.ant-form-item-control > div > div > div > label:nth-child(2) */

.shoulder-type-radio .ant-radio-group > label:nth-child(1) {
  display: block;
  margin-bottom: 5px;
}

.shoulder-type-radio .ant-radio-group > label:nth-child(4) {
  width: 50%;
  margin-bottom: 5px;
}

.ant-layout-sider-children {
  overflow-x: auto !important;
}

.table-filter {
  display: flex;
  padding: 0 24px;
  background-color: white;
  width: 100%;
}

.table-filter-conditions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  padding-bottom: 10px;

  @media (max-width: var(--screen-md)) {
    flex-direction: column;
    justify-content: end;
  }

  .table-filter-col {
    padding-right: 8px;
    padding-bottom: 5px;
  }
}

.table-filter-buttons {
  padding-bottom: 5px;

  @media (max-width: var(--screen-md)) {
    padding-bottom: 15px;
  }
}

[data-theme='compact']{
  margin-bottom: 12px;
  overflow: hidden;
  /*background: #f7f7f7;*/
  border: 0;
  border-radius: 2px;
}
.site-collapse-custom-collapse{
  margin-top: 0 !important;
}
.site-collapse-custom-collapse .ant-collapse-item.ant-collapse-item-active {
  margin-bottom: 0 !important;
}
.site-collapse-custom-collapse .ant-collapse-item{
  transition: margin-bottom .2s linear;
  margin-bottom: 24px !important;
}

.site-collapse-custom-collapse .site-collapse-custom-panel {
  overflow: hidden;
  border: 0;
  border-radius: 2px;
}

.site-collapse-custom-panel > .ant-collapse-content > .ant-collapse-content-box{
    padding: 0 !important;
}

.ant-collapse-header {
  border-bottom: 0;
  padding: 2px 4px 4px 2px !important;
}

.error-text {
  color: red;
}

.pl-2 {
  padding-left: 1em;
}

.hide-label .ant-col.ant-form-item-label {
  display: none;
}

.collapse-panel-label{
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;
  font-size: 14px;
  color: rgba(0,0,0,0.85);
}

.create-adjustment-categories {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: fit-content;
  column-gap: 12px;
  row-gap: 12px;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(6, 1fr);
  }
}